<template>
	<div id="official">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">微官网管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index == teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content">
				<div class="u-f">
					<div class="right">
						<div class="title u-f-item u-f-jsb">
							<div>共{{ total }}条数据</div>
							<el-button size="small" @click="addVisible=true">添加轮播图</el-button>
						</div>
						<el-table :data="bannerList" height="83%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
							<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
							<el-table-column label="序号" type="index" width="65" align="center"></el-table-column>
							<el-table-column label="图片" align="center">
								<template slot-scope="scope">
									<el-image
										fit="cover"
									    style="width: 50px; height: 50px"
									    :src="'http://hlgzx.zhiyunapi.com'+scope.row.pic" 
									    :preview-src-list="['http://hlgzx.zhiyunapi.com'+scope.row.pic]">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="addtime" label="创建时间" align="center"></el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button @click="openDialog(2,scope.row)" type="text" size="small">编辑</el-button>
									<el-button @click="delcourse(scope.row.id)" type="text" size="small" style="color:red;">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-right" style="padding: 15px 0;">
							<!-- <el-button type="danger" plain size="mini" @click="delUser">删除</el-button> -->
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
			

			<!-- 编辑 -->
			<el-dialog title="编辑轮播图" :visible.sync="editVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>图片
						</div>
						<el-upload
							class="avatar-uploader"
							action="http://hlgzx.zhiyunapi.com/v1/school_api/common/upload"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
							:headers="headers"
						>
							<img v-if="imageUrl" :src="imageUrl" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editVisible = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit(2)">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加 -->
			<el-dialog title="添加轮播图" :visible.sync="addVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>图片
						</div>
						<el-upload
							class="avatar-uploader"
							action="http://hlgzx.zhiyunapi.com/v1/school_api/common/upload"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
							:headers="headers"
						>
							<img v-if="imageUrl" :src="imageUrl" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addVisible = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit(1)">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			teacherMeun: [
				{ name: '资讯管理',id:1},
				{ name: '轮播图管理',id:2}
			],
			teacherIndex: 1,
			addVisible:false,
			editVisible:false,
			headers:{
				token:window.localStorage.getItem("token")
			},
			imageUrl: '',
			image:'',
			action:'/common/upload',
			total:0,
			page:1,
			limit:10,
			bannerList:[],
			type:2
			
		};
	},
	mounted() {
		this.getBannerList()
	},
	methods: {
		handleAvatarSuccess(res, file) {
			if(res.code==1){
				this.imageUrl = res.data.fullurl;
				this.image = res.data.url;
			}else{
				this.$message.error(res.msg)
			}
			
		},
		beforeAvatarUpload(file) {
			console.log(file);
		},
		twacherTap(index){
			this.teacherIndex = index;
			if(index==0){
				this.$router.push('/official')
			}else{
				this.$router.push('/setBanner')
			}
		},
		// 关闭弹窗
		handleClose(done) {
			this.editVisible = false;
			this.addVisible = false;
			done();
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
		},
		openDialog(type, item) {
			if (type == 1) {
				this.addVisible = true;
			} else {
				this.editVisible = true;
				this.id = item.id;
				this.imageUrl = 'http://hlgzx.zhiyunapi.com'+item.pic;
				this.image = item.pic;
			}
		},
		submit(type) {
			let api = this.$api.setting.addBanner;
			let data = {
				pic:this.image,
				sort:1
			};
			if (type == 2) {
				data.id = this.id;
				api = this.$api.setting.editBanner;
			}
			api(data).then(res => {
				if (res.data.code == 1) {
					if (type == 1) {
						this.$message.success('添加成功');
					} else {
						this.$message.success('修改成功');
					}
					this.imageUrl = '';
					this.image = '';
					this.addVisible = false;
					this.editVisible = false;
					this.getBannerList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		delcourse(id) {
			let _this = this;
			_this
				.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				})
				.then(() => {
					_this.$api.setting
						.delBanner({
							id: id
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.getBannerList();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
		},
		getBannerList(){
			this.$api.setting
				.getBannerList({
					page: this.page,
					pagesize:this.limit
				})
				.then(res => {
					if (res.data.code == 1) {
						this.bannerList = res.data.data;
						this.total = res.data.total;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		}
	}
};
</script>

<style lang="scss">
#official {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 70vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.active {
					background-color: #e4f1ff;
					color: #007aff;
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 78vh;
			width: 100%;
			padding: 25px 50px 0 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.upload-file {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 50px;
				color: #8c939d;
				width: 150px;
				height: 150px;
				line-height: 150px;
				text-align: center;
			}
			.avatar {
				width: 150px;
				height: 150px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
